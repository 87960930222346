import { createSelector } from 'reselect';
import { filter, propEq } from 'ramda';

import { State } from 'src/reducers';
import { Organization } from 'src/reducers/organizations';
import { getCurrentOrgId, getCurrentAppMembers, getCurrentAppId } from './current_app';
import { getCurrentUserId } from './user';

export const getOrganizations = (state: State) => state.organizations || {};
export const getOrgs = createSelector(
  getOrganizations,
  (organizations) => organizations.orgs || {}
);

export const getOrgsPure = createSelector(
  getOrgs,
  (orgs) => {
    return Object.keys(orgs).reduce(
      (acc, orgId) => {
        const data = { ...orgs[orgId] };
        delete data.lastUpdated;
        acc[orgId] = data;
        return acc;
      },
      {} as Record<string, Organization>
    );
  }
);

export const getOrganizationApps = createSelector(
  getOrgs,
  getCurrentOrgId,
  (orgs, currentOrgId) => {
    const currentOrg = orgs[currentOrgId];
    return currentOrg ? currentOrg.apps : [];
  }
);

export const getOrgsLoading = createSelector(
  getOrganizations,
  (organizations) => organizations.organizationsLoading || false
);
export const getOrgsLastUpdated = createSelector(
  getOrganizations,
  (organizations) => organizations.lastUpdated || 0
);
export const getCurrentOrg = createSelector(
  getCurrentOrgId,
  getOrgs,
  (id, orgs) => orgs[id] || {}
);
export const getCurrentApps = createSelector(
  getCurrentOrg,
  (org) => org.apps || []
);
export const getCurrentOrgMembers = createSelector(
  getCurrentOrg,
  (org) => org.members || []
);

export const getOrgById = createSelector(
  (_store: State, orgId: string) => orgId,
  getOrgs,
  (id, orgs) => orgs[id] || {}
);

export const getOrgAppsLimitById = createSelector(
  getOrgById,
  ({ plan = {} }) => {
    return plan.app_limit || null;
  }
);

export const getOrgRemainAppsById = createSelector(
  getOrgById,
  ({ apps = [], plan = {} }) => {
    return plan.app_limit ? plan.app_limit - apps.length : null;
  }
);

export const getOrgLoading = createSelector(
  getCurrentOrg,
  (org) => org.organizationLoading || false
);
export const getOrgLastUpdated = createSelector(
  getCurrentOrg,
  (org) => org.lastUpdated || 0
);
export const getOrgPlan = createSelector(
  getCurrentOrg,
  (org) => org.plan || {}
);
export const getCurrentOrgName = createSelector(
  getCurrentOrg,
  (org) => org.name || ''
);
export const getOrgExpired = createSelector(
  getOrgPlan,
  (plan) => plan.expired || false
);
export const getOrgTier = createSelector(
  getOrgPlan,
  (plan) => plan.tier || ''
);

// Billing Contact
export const getCurrentBillingContactId = createSelector(
  getCurrentOrg,
  (org) => org.billing_contact_id || ''
);
export const getCurrentBillingContact = createSelector(
  getCurrentBillingContactId,
  getCurrentAppMembers,
  (billingContactId, appMembers) => {
    const isBillingContact = propEq('user_id', billingContactId);
    const billingContacts = filter(isBillingContact, appMembers);
    if (billingContacts.length > 0) {
      return billingContacts[0];
    }
    return {} as any;
  }
);
export const getCurrentBillingContactName = createSelector(
  getCurrentBillingContact,
  (billingContact) => billingContact.name || ''
);
export const getCurrentBillingContactEmail = createSelector(
  getCurrentBillingContact,
  (billingContact) => billingContact.email || ''
);

export const getBillingContactsUserId = createSelector(
  getCurrentAppId,
  getOrgs,
  (currentAppId, orgs) => (orgs[currentAppId] ? orgs[currentAppId].billing_contact_id : '')
);

export const isCurrentUserBillingContact = createSelector(
  getBillingContactsUserId,
  getCurrentUserId,
  (billingContactId, userId) => !!billingContactId && billingContactId === userId
);
