import { any, isEmpty, omit, pick, sortBy, values } from 'ramda';
import camelCaseKeys from 'src/api/camelcase-keys';
import { getTextFromHtml, getIsStringContainHtml } from 'src/components/molecules/util-handlers';
import {
  PromptMultiAppsItem,
  ImageLayout,
  PromptPayload,
} from 'src/reducers/prompts-multi-apps/prompts-multi-apps.types';
import {
  API_VERSION_WITH_NO_RICH_TEXT_PROMPTS,
  API_VERSION_WITH_RICH_TEXT,
  InteractionType,
  Platform,
  PromptAction,
  PromptActionType,
} from 'src/types/core';
import { eventNameToHuman } from 'src/utils/events';

export const WEB_IMAGE_SCALE = 1;
export const MOBILE_IMAGE_SCALE = 3;

const defaultImage: PromptMultiAppsItem['image'] = {
  file: '',
  title: '',
  layout: ImageLayout.FULL_WIDTH,
  altText: '',
  scale: MOBILE_IMAGE_SCALE,
};

export const defaultModel: PromptMultiAppsItem = {
  id: '',
  orgId: '',
  canLaunch: false,
  createdBy: '',
  type: InteractionType.TextModal,
  interactionData: [
    {
      appId: '',
      platform: Platform.iOS,
      active: false,
      actions: [
        {
          label: 'Dismiss',
          interactionType: PromptActionType.Dismiss,
          order: 0,
        },
      ],
      codePoints: [],
    },
  ],
  name: '',
  title: '',
  body: '',
  image: undefined,
  maxHeight: undefined,
};

export const isAction = {
  Close: (action: PromptAction) => action.interactionType === PromptActionType.Cancel,
  Dismiss: (action: PromptAction) => action.interactionType === PromptActionType.Dismiss,
  Survey: (action: PromptAction) => action.interactionType === PromptActionType.Survey,
  AlchemerSurvey: (action: PromptAction) => action.interactionType === PromptActionType.AlchemerSurvey,
  Link: (action: PromptAction) => action.interactionType === PromptActionType.Link,
  MessageCenter: (action: PromptAction) => action.interactionType === PromptActionType.MessageCenter,
  NilClass: (action: PromptAction) => action.interactionType === PromptActionType.NilClass,
  Initiator: (action: PromptAction) => action.interactionType === PromptActionType.Initiator,
};

export class PromptModel {
  static Defaults = defaultModel;
  static DefaultImage = defaultImage;
  static sortActionsByOrder = sortActionsByOrder;
  static oldPromptToNew = oldPromptToNew;
  static toLegacy = toLegacy;

  static getIsContainHtmlFields(model: PromptMultiAppsItem): boolean {
    return getIsStringContainHtml(model.title) || getIsStringContainHtml(model.body);
  }

  static getParsedHtmlFieldsBeforeSave(model: PromptMultiAppsItem): PromptMultiAppsItem {
    return {
      ...model,
      title: getIsStringContainHtml(model.title) ? model.title : (getTextFromHtml(model.title) as string),
      body: getIsStringContainHtml(model.body) ? model.body : (getTextFromHtml(model.body) as string),
    };
  }

  static getInitialSection(model?: PromptMultiAppsItem): 'targeting' | 'reporting' | 'content' | 'apps' {
    const isNoteJustCreated = localStorage.getItem('isNoteJustCreated');
    if (isNoteJustCreated) {
      localStorage.removeItem('isNoteJustCreated');
      return 'targeting';
    }

    return model && this.hasAnswers(model) ? 'reporting' : 'apps';
  }

  static getApiVersion(model: PromptMultiAppsItem): string {
    return this.getIsContainHtmlFields(model) ? API_VERSION_WITH_RICH_TEXT : API_VERSION_WITH_NO_RICH_TEXT_PROMPTS;
  }

  // README: https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/index.js#L97
  static invokeEvents({ interactionData }: PromptMultiAppsItem): string[] {
    const codePoints = interactionData[0]?.codePoints;
    if (Array.isArray(codePoints)) {
      return (codePoints || []).map((code) => eventNameToHuman(code));
    }
    if (typeof codePoints === 'string') {
      return [eventNameToHuman(codePoints)];
    }
    return [];
  }

  static isValid(model: PromptMultiAppsItem): boolean {
    const requiredProps = pick(['name'], model);
    const hasContent = !!(model.title || model.body || model.image);
    const hasActions = model.interactionData.some(({ actions = [] }) => actions.length > 0);

    return !any(isEmpty)(values(requiredProps)) && hasContent && hasActions;
  }

  static setData(model: PromptMultiAppsItem, data: Partial<PromptMultiAppsItem>): PromptMultiAppsItem {
    return { ...model, ...data };
  }

  static setImageData(model: PromptMultiAppsItem, data: Partial<PromptMultiAppsItem['image']>): PromptMultiAppsItem {
    return {
      ...model,
      image: model.image
        ? ({ ...model.image, ...data } as PromptMultiAppsItem['image'])
        : ({ ...data } as PromptMultiAppsItem['image']),
    };
  }

  static removeAction(model: PromptMultiAppsItem, pos: number): PromptMultiAppsItem {
    const actions = model.interactionData[0].actions;
    if (model.id) {
      actions?.splice(pos, 1, { ...actions[pos], _destroy: 1 });
    } else {
      actions?.splice(pos, 1);
    }
    return {
      ...model,
      interactionData: [...model.interactionData],
    };
  }

  static getActions(model: Pick<PromptMultiAppsItem, 'interactionData'>): PromptAction[] {
    const actions = model.interactionData[0]?.actions || [];
    return sortActionsByOrder(actions.filter((item) => !item._destroy && !isAction.Close(item)));
  }

  // https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/tab_reporting.js#L79
  // https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/notes/tab_reporting.js#L57
  static getReportedActions(model: PromptMultiAppsItem): PromptAction[] {
    const actions = model.interactionData[0]?.actions || [];
    const sortByCount = (actions: PromptAction[]) => sortBy((item) => -(item.execCount || 0), actions);
    return sortByCount(actions.filter((item) => (item.execCount || 0) > 0));
  }

  static hasImageFile(image: PromptMultiAppsItem['image']): boolean {
    return Boolean(image?.file);
  }

  static isImageAlreadyUploaded(image: PromptMultiAppsItem['image']): boolean {
    return this.hasImageFile(image) && Boolean(typeof image?.file === 'string');
  }

  static hasAnswers(model: PromptMultiAppsItem): boolean {
    return !isEmpty(this.getReportedActions(model));
  }

  static isActiveAction(action: PromptAction): boolean {
    return action._destroy ? false : !isAction.Close(action);
  }

  static getActionsSurveyIds(model: PromptMultiAppsItem): string[] {
    const actions = model.interactionData[0]?.actions || [];
    return actions
      .filter((action) => action.label && action.label.toLowerCase() === 'survey')
      .map((action) => action.interactionId) as string[];
  }

  static hasInvalidActions(model: PromptMultiAppsItem, surveys: { id?: string }[]): boolean {
    const actions = model.interactionData[0]?.actions || [];
    return actions.some(
      (action) =>
        action.interactionType === PromptActionType.Survey &&
        !surveys.some(({ id }: { id?: string }) => id === action.interactionId),
    );
  }

  static convertPromptToPayload(prompt: PromptMultiAppsItem) {
    const interactionData = prompt.interactionData.map((interaction) => {
      const actions = interaction.actions?.map(
        (action): PromptAction =>
          isAction.AlchemerSurvey(action)
            ? {
                ...action,
                interactionType: PromptActionType.Link,
              }
            : action,
      );
      return {
        ...interaction,
        actions,
      };
    });
    return this.toLegacy({
      ...prompt,
      interactionData,
    }) as unknown as PromptMultiAppsItem;
  }

  static convertPayloadToPrompt(note: PromptPayload): PromptMultiAppsItem {
    const updatedInteractionData = note.interaction_data.map((data) => {
      const actions = data.actions?.map((action) =>
        action.append_variables
          ? {
              ...action,
              interaction_type: PromptActionType.AlchemerSurvey,
            }
          : action,
      );

      return { ...data, actions };
    });

    return this.oldPromptToNew({ ...note, interactionData: updatedInteractionData } as never);
  }
}

function sortActionsByOrder(actions: PromptAction[]) {
  return sortBy((a) => (a.order !== undefined ? a.order : Number.MAX_VALUE), actions);
}

function oldPromptToNew(item: never) {
  return camelCaseKeys(item) as PromptMultiAppsItem;
}

function toLegacy(model: PromptMultiAppsItem) {
  const getActions = (action?: PromptAction) =>
    !action
      ? []
      : {
          id: action.id || null,
          label: action.label,
          interaction_type: action.interactionType,
          order: action.order || null,
          exec_count: action.execCount || null,
          interaction_id: action.interactionId || null,
          append_variables: action.appendVariables || null,
          url: action.url || null,
          target: action.target || null,
          _destroy: action._destroy || null,
        };

  const optinalFields = {
    updated_at: model.updatedAt || null,
    created_at: model.createdAt || null,
    display_type: model.displayType || null,
    start_time: model.startTime || null,
    end_time: model.endTime || null,
    first_active_at: model.firstActiveAt || null,
    image: model.image || null,
    id: model.id || null,
  };
  return {
    ...omit(
      [
        'canLaunch',
        'displayType',
        'firstActiveAt',
        'updatedAt',
        'createdAt',
        'maxHeight',
        'startTime',
        'endTime',
        'createdBy',
        'interactionData',
        'orgId',
      ],
      model,
    ),
    name: model.name,
    title: model.title,
    body: model.body,
    can_launch: model.canLaunch,
    max_height: model.maxHeight,
    interaction_data: model.interactionData.map((data) => ({
      app_id: data.appId,
      platform: data.platform,
      active: data.active,
      code_points: data.codePoints,
      criteria: data.criteria,
      triggered_by: data.triggeredBy,
      actions: data.actions?.map(getActions),
      archived_actions: data.archivedActions?.map(getActions),
    })),
    org_id: model.orgId,
    created_by: model.createdBy,
    type: model.type,
    ...optinalFields,
  };
}
